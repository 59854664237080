.mobile-only{
	display: none;
}

.news-desktop{
	display: block;
}

.news-responsive{
	display: none;
}

.studeren .mobile-wrap{
	display: none;
}

.studeren .mobile-menu{
	display: none;
}

@media only screen and (max-width: 1199px){

	.header-image{

		height: 350px;
	}

	.page-template-template-front .header-image {

	    height: 400px;

	}

	.studeren .desktop-wrap{
		display: none;
	}

	.studeren .mobile-wrap{
		display: block;
	}

	.main-row-studeren{
		margin-left: -15px !important;
	}

	.schoolkosten{
		.item-wrap{
			.item-schoolkosten{
				padding: 0 25px;
			}
		}
	}

}

@media only screen and (max-width: 991px){

	.mobile-only{
		display: block;
	}

	.top-bar-wrap{
		display: none;
	}

	#menu-entries{
		padding: 15px 25px;
		.nav{
			.nav-item{
				margin-bottom: 10px;
				display: inline-block !important;
				width: auto !important;
				.nav-link{
					border-bottom: none !important;
					display: inline-block !important;
					width: auto !important;
				}
			}
		}

		.smartschool{
			display: block;
		}

		.search-widget{
			display: block !important;
		}
	}

	#nav-main #menu-entries .navbar-nav .nav-item{
		margin-left: 0px !important;
		margin-right: 0px !important;
	}

	#menu-entries .navbar-nav .dropdown .dropdown-sub {
	    position: static !important;
	}

	.dropdown-menu{
		box-shadow: none !important;
		border: none !important;
	}

	.navbar-light .navbar-toggler{
		border-radius: 0px;
		background-color: $blue;
		border: none;
		padding: 6px 14px;
		color: white !important;
	}

	.search-widget.mobile-only{
		margin-top: 15px;

		#searchform{

			input[type="text"]{
				border-radius: 0px;
				color: $green;
				border-bottom: 2px solid $green;
				background-color: transparent;
			}
		}
	}

	.navbar-toggler .navbar-toggler-icon {
	    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255,255,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}

	.header-image{
		background-attachment: scroll !important;
	}

	.intro .home-title {
		background: none !important;
		background-clip: initial !important;
	  	-webkit-text-fill-color: initial !important;
	  	color: $green !important;
	}

	.news-desktop{
		display: none;
	}

	.news-responsive{
		display: block;
	}

	.news .row{
	    margin-bottom: 30px;
	}

	.more-news{
		margin-top: 25px !important;
	}

	.feed{
		margin-top: 25px;
	}

	footer{
		padding-left: 0 !important;
		padding-right: 0 !important;
		padding-top: 50px;

		.col-md-6{
			margin-bottom: 25px;
		}

		.social-media{
			border-left: none !important;
			padding: 0 15px !important;
		}

		.disclaimer{
			margin-top: 50px;
		}
	}

	.studeren .mobile-wrap .derde-graad {
	    margin-top: 0;
	}

	.schoolkosten{
		.item-wrap{
			margin: 50px 0;
		}
	}

}

@media only screen and (max-width: 767px){

	.header-image{
		height: 200px;
		margin-bottom: 0px;
	}

	.page-template-template-front .header-image {

	    height: 225px;

	}

	.home-video{
		overflow: hidden;

		img{
			max-width: 100%;
			box-shadow: none !important;
		}

		&:after{
		}
	}

	h1{
	    font-family: 'Raleway', sans-serif !important;
		font-size: 26px !important;
		line-height: 44px;
		font-weight: 700 !important;
		color: $green;
		margin-bottom: 30px !important;
	}

	.news .row{
	    margin-bottom: 0px;
	}

	.news-overview{
		padding-top: 25px;
	}

	.news-overview .row{
	    margin-bottom: 0px;
	}

	.news-article-wrapper{
		margin-bottom: 30px;
	}

	.link-wrapper-bottom, .text-wrapper{
		margin-bottom: 25px !important;
	}

	.studeren .mobile-wrap .leerjaar{
		padding: 0;
	}

	.studeren .mobile-wrap .mobile-menu{
		display: block;
	}

	.studeren .mobile-wrap .leerjaar{
		display: none;
	}

	.studeren .mobile-wrap .leerjaar.reveal{
		display: block;
	}

	.schoolkosten{
		.item-wrap{
			.item-schoolkosten{
				padding: 0 10px;
			}
		}
	}

}