/* INTRO */

.intro{
	margin-bottom: 125px;
	.home-title{
		display: block;
		max-width: 950px;
		background: linear-gradient(135deg, #38B983 0%, #278982 100%);
		background-clip: text;
	  	-webkit-text-fill-color: transparent;
	}

	.home-video{
		width: 100%;
		position: relative;
		text-align: right;

		img, video, iframe{
			max-width: 100%;
			box-shadow: 20px 40px 0px 0px $green;
		}

		&:after{
			content: "";
		    position: absolute;
		    bottom: -34px;
		    right: -20px;
		    width: 0;
		    height: 0;
		    border-left: 546px solid transparent;
		    border-right: 0 solid transparent;
		    border-bottom: 20px solid white;
		    z-index: 1;
		}
	}
}

/* NEWS */

.news{
	padding: 100px 0;
	background-color: $blue;

	h2{
		color: white;
		text-align: center;
		margin-bottom: 50px;

		&:after{
			content: "";
			border-bottom: 5px solid white;
			opacity: 0.2;
			display: block;
			width: 100px;
			margin: 0 auto;
			margin-top: 10px;
		}
	}

	.news-article{

		background-color: white;
		height: 100%;
		box-shadow: 0 2px 40px 0 rgba(0,0,0,0.15);

		.image-wrapper{

			padding: 0 !important;
			width: 100%;
			height: 200px;
			overflow: hidden;

			a{
				border-bottom: 0 !important;

				img{
					width: 100%;
					height: 100%;
					-o-object-fit: cover;
					object-fit: cover;
					object-position: 100% 0%;
				}

			}
		}

		.text-wrapper{
			padding: 35px 25px 25px 25px;

			.title-link{
				border-bottom: 3px solid transparent;
				transition: all ease-in 0.1s;

				&:hover{
					border-bottom: 3px solid $green;
				}

				h3{
					font-size: 20px;
					line-height: 28px;
					font-weight: bold;
					margin-bottom: 20px;
					border-bottom: 3px solid transparent;
					display: inline-block !important;
					transition: all ease-in 0.05s;
					color: black !important;

					&:hover{
						color: $green !important;
					}
				}
			}

			.lees-meer{
				display: inline-block;
				color: white !important;
				background-image: linear-gradient(164deg, #38B983 0%, #278982 100%);
				border-radius: 25px;
				padding: 12px 80px;
				border: 3px solid white;
				transition: all ease-in 0.1s;

				&:hover{
					color: $green !important;
					background-image: none;
					background-color: transparent;
					border: 3px solid $green;
				}
			}
		}
	}

	.more-news{
		margin-top: 100px;
		text-align: center;

		h3{
			color: white;
			font-size: 24px;
			margin-bottom: 15px;
		}

		a{
			display: inline-block;
			color: white !important;
			background-image: linear-gradient(164deg, #38B983 0%, #278982 100%);
			border-radius: 25px;
			padding: 12px 39px;
			border: 3px solid $blue;
			transition: all ease-in 0.1s;
			text-decoration: none;

			&:hover{
				color: $green !important;
				background-image: none;
				background-color: white;
				border: 3px solid $green;
			}
		}
	}
}

/* LINKS */

.links{
	padding-top: 100px;
	padding-bottom: 100px;

	h2{
		color: $blue;
		text-align: center;
		margin-bottom: 50px;
	}

	.link-wrapper{
		background-color: #F2F2F2;
		margin-bottom: 25px;
		padding: 35px 25px 25px 25px;

		h3{
			color: $green;
			font-size: 20px;

			&:after{
				content:"";
				border-bottom: 5px solid rgba(56, 185, 131, 0.2);
				display: block;
				width: 50px;
				margin-top: 10px;
			}
		}

		ul{
			margin: 0;
			padding: 0;

			li{
				padding: 5px 0;
				list-style-type: none;
				border-bottom: 1px solid rgba(22, 100, 131, 0.25);

				a{
					color: black !important;
					text-decoration: none;

					&:hover{
						color: $blue !important;
					}
				}

				&:last-of-type{
					border-bottom: none;
				}
			}
		}
	}

	.link-wrapper-bottom{
		margin-bottom: 0;
	}

	.text-wrapper{
		background-color: $blue;
		padding: 35px 25px 25px 25px;

		h3{
			color: white;
			font-size: 20px;

			&:after{
				content:"";
				border-bottom: 5px solid white;
				opacity: 0.2;
				display: block;
				width: 50px;
				margin-top: 10px;
			}
		}

		p{
			padding-top: 5px;
			color: white !important;
			line-height: 32px;
			margin-bottom: 5px;
		}

		ul{
			padding-left: 0;
		}

		li{
			padding: 5px 0;
    		list-style-type: none;
    		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
			
			a{
				color: white !important;

				&:hover{
					text-decoration: none;
				}
			}
		}
	}

	.feed{
		padding: 35px 25px 25px 25px;
		background-color: #F2F2F2;

		h3{
			color: $green;
			font-size: 20px;

			&:after{
				content:"";
				border-bottom: 5px solid rgba(56, 185, 131, 0.2);
				display: block;
				width: 50px;
				margin-top: 10px;
			}
		}
	}
}