footer{
	background: linear-gradient(135deg, #38B983 0%, #278982 100%);
	padding-top: 100px;
	padding-bottom: 50px;

	.footer-widgets{
		h3{
			font-family: 'Raleway', sans-serif;
			font-weight: lighter !important;
			color: white;
			font-size: 16px;
			margin-bottom: 25px;
		}

		p{
			color: white;
			margin-bottom: 24px;

			.inschrijven-cta{
				display: inline-block;
				background-color: $blue;
				color: white !important;
				padding: 10px 50px;
				border-radius: 25px;
				text-decoration: none;
				transition: all ease-in 0.1s;

				&:hover{
					background-color: #206E8D;
				}
			}
		}

		ul{
			padding: 0;

			li{
				list-style-type: none;
				a{
					color: white !important;
					text-decoration: none;
					transition: all ease-in 0.1s;

					&:hover{
						color: $blue !important;
					}
				}
			}
		}

		.social-media{
			padding: 0 100px;
			border-left: 2px solid rgba(255,255,255,0.25);
			a{
				display: inline-block;
				text-decoration: none;
				margin-right: 25px;

				&:last-of-type{
					margin-right: 0;
				}

				img{
					height: 30px;
				}
			}
		}
	}

	.disclaimer{
		color: white;
		display: block;
		width: 100%;
		text-align: center;

		a{
			color: white !important;
			transition: all ease-in 0.1s;
			text-decoration: none;

			&:hover{
				color: $blue !important;
			}
		}

		span{
			display: inline-block;
			padding: 0 3px;
		}
	}

	.other-links{
		margin-bottom: 25px;

		img{
			margin-right: 25px;
		}
	}

	.footer-cta{
		display: inline-block;
		background-color: $blue;
		color: white !important;
		padding: 12px 45px;
		border-radius: 25px;
		text-decoration: none;
		transition: all ease-in 0.1s;

			&:hover{
				background-color: #206E8D;
			}

	}
}