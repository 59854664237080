/**
 * Global variables
 **/

$image_path: 'images/';

/**
 * Colors
 **/

$primary-color: #FFFFF;
$secondary-color: #FFFFFF;

$white: #FFFFFF;
$black: #000000;