.schoolkosten{
	.item-wrap{
		.item-schoolkosten{

			padding: 0 50px;

			.icon-wrap{
				display: block;
				width: 100%;
				text-align: center;
				border: 0;
			}

			h3{
				text-align: center;
			}

			.content{
				text-align: center;
			}

			.button-wrap{
				text-align: center;

				a{
					display: inline-block;
					text-align: center;
					margin-top: 25px;
					color: white !important;
					padding: 8px 25px;
					background-color: #166483;
					box-shadow: 0 2px 40px 0 rgba(22, 100, 131, 0.25);
					border-radius: 25px;
					transition: all ease-in 0.1s;
					border-bottom: 0;

					&:hover{
						background-color: rgba(22, 100, 131, 0.85);
					}
				}

			}

		}
	}
}