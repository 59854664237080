/* Cookie Notice */

.cookie-notice-container{

	background-color: $blue !important;

	#cn-notice-buttons{

		.button.bootstrap{
			background-image: linear-gradient(164deg, #38B983 0%, #278982 100%) !important;
			box-shadow: none !important;
			border-radius: 10px !important;
			transition: all ease-in-out 0.1s;
			border: none !important;

			&:hover{
				background-position: 0 !important;
				background-color: transparent !important;
				background-image: linear-gradient(150deg, #38B983 0%, #278982 100%) !important;
			}
		}

	}

}

