.main-row-studeren{
	margin-left: 0 !important;
}

.studeren{

	.desktop-wrap{
		font-size: 14px !important;
		line-height: 19px;
		padding-top: 25px;

		.graad{
			position: relative;
			.graad-img{
				position: absolute;
				left: -145px;
				z-index: 20;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		.studeren-row{

			position: relative;

			img{
				position: absolute;
				left: -25px;
				z-index: 10;
			}

			.studeren_1{
				top: 7px;
			}

			.studeren_3{
				top: 10px;
			}

			.studeren_4{
				top: 4px;
			}

			.studeren_5{
				top: -5px;
				left: -28px;
			}

			.studeren_6{
				top: -7px;
			}

			&::after{
				content: '';
				width: 95px;
				height: 100%;
				position: absolute;
				left: -95px;
				z-index: 1;
			}

			.table-wrap{
				padding-right: 0;
				table{
					width: 100%;
					tr{
						padding: 3px 0;

						th{
							text-align: center;
							color: white;
							font-weight: bold;
						}

						td{
							text-align: center;
						}

						td.richtingen-wrap{

							.richtingen{
								tr{
									.richting{
										padding: 0 3px;

										&:first-of-type{
											padding-left: 0;
										}

										&:last-of-type{
											padding-right: 0;
										}

										.link-wrap{

											padding: 7px 0;
											position: relative;

											a{
												color: black !important;
												border: none;
												font-weight: 400 !important;
												position: absolute;
												top: 50%;
												left: 50%;
												transform: translate(-50%,-50%);
												width: 100%;
											}

										}
									}
								}
							}
						}
					}
				}
			}

		}

		.eerste-graad{

			.studeren-row{
				margin-bottom: 15px;
				background-color: #d4e3f5;
				width: 100%;

				&::after{
					background-color: #d4e3f5;
				}

				.table-wrap{
					table{
						tr{
							th{
								background-color: $blue;
							}

							td.richtingen-wrap{

								.richtingen{
									tr{
										.richting{


											.link-wrap{
												min-height: 72px;
												background-color: #bcd1e2;

												a{

													&:hover{
														color: $blue !important;
													}

													b{
														color: $blue !important;
													}
												}

											}

											.link-wrap.richting-1B{
												min-height: 91px;
											}
										}
									}
								}
							}

							.x-plore{
								background-color: #8eb1c5;
								color: white;
								font-style: italic;
							}
						}
					}
				}

			}
		}

		//Tweede graad

		.tweede-graad{

			.studeren-row{
				margin-bottom: 15px;
				background-color: #d7ede6;
				width: 100%;

				&::after{
					background-color: #d7ede6;
				}

				.table-wrap{
					table{
						tr{
							th{
								background-color: #268881;
							}

							td.richtingen-wrap{

								.richtingen{
									tr{
										.richting{


											.link-wrap{
												min-height: 100px;
												background-color: #a6c8c8;

												a{

													&:hover{
														color: #268881 !important;
													}

													b{
														color: #268881 !important;
													}
												}

											}

											.gesplitst{
												min-height: 20px;
												bottom: 18px;

												.optie{
													position: relative;
													top: 20px;

													&:after{
														content: '';
														border: 1px solid #a6c8c8;
														height: 8px;
														position: absolute;
														top: -7px;
														left: 49%;
													}
												}
											}
										}
									}
								}
							}

						}
					}
				}

			}
		}

		//Derde graad

		.derde-graad{

			.studeren-row{
				margin-bottom: 15px;
				background-color: #e4f2e7;
				width: 100%;

				&::after{
					background-color: #e4f2e7;
				}

				.table-wrap{
					table{
						tr{
							th{
								background-color: $green;
							}

							td.richtingen-wrap{

								.richtingen{
									tr{
										.richting{


											.link-wrap{
												min-height: 72px;
												background-color: #b3ddca;

												a{

													&:hover{
														color: $green !important;
													}

													b{
														color: $green !important;
													}
												}

											}


										}
									}
								}
							}

						}
					}
				}

			}
		}
	}

	.mobile-wrap{

		.graad{
			padding: 0;

			.leerjaar{

				.img-wrap{
					text-align: center;
					padding: 15px 0;
				}

				.heading{
					color: white;
					text-align: center;
					padding: 5px 7px;
					font-weight: bold;
				}

				.richtingen-wrap{

					a{
						display: block;
						font-weight: 400 !important;
						color: black !important;
						border: none !important;
						text-align: center;
						padding: 5px 7px;

					}

				}

			}

		}

		.eerste-graad{

			.heading{
				background-color: $blue;
			}

			.X-plore{
				background-color: #8eb1c5;
				text-align: center;
				padding: 5px 0;
				color: white !important;
				font-style: italic;
			}

			.richtingen-wrap{

				a{

					&:nth-of-type(odd){
						background-color: #bcd1e2;
					}

					&:nth-of-type(even){
						background-color: #d4e3f5;
					}

					b{
						color: $blue;
					}

				}

			}

		}

		.tweede-graad{

			.heading{
				background-color: #268881;
			}

			.richtingen-wrap{

				a{

					&:nth-of-type(odd){
						background-color: #a6c8c8;
					}

					&:nth-of-type(even){
						background-color: #d7ede6;
					}

					b{
						color: #268881;
					}

				}

			}

		}

		.derde-graad{
			margin-top: 25px;

			.heading{
				background-color: $green;
			}

			.richtingen-wrap{

				a{

					&:nth-of-type(odd){
						background-color: #b3ddca;
					}

					&:nth-of-type(even){
						background-color: #e4f2e7;
					}

					b{
						color: $green;
					}

				}

			}

		}

	}

	.mobile-menu{
		margin: 0 auto;
		
		.menu{
			max-width: 250px;

			.heading{
				padding: 0;
				text-align: center;
				text-transform: uppercase;
				font-weight: bold;
				color: white;
				padding: 15px 10px;
			}

			.button-wrap{
				padding: 0;

				a{
					display: block;
					width: 100%;
					height: 100%;
					padding: 10px;
					text-align: center;
					font-size: 32px;
					font-weight: bold;
					border: none !important;
				}
			}

			.eerste-graad{

				.heading{
					background-color: $blue;
				}

				.button-wrap{

					a{
						color: $blue !important;
						background-color: #8eb1c5;
					}

				}	
			}

			.tweede-graad{

				.heading{
					background-color: #268881;
				}

				.button-wrap{

					a{
						color: #268881 !important;
						background-color: #a6c8c8;
					}

				}	
			}

			.derde-graad{

				.heading{
					background-color: $green;
				}

				.button-wrap{

					a{
						color: $green !important;
						background-color: #b3ddca;
					}

				}	
			}

		}
	}

}