/* Top bar */
.navbar:before,
.navbar:after,
.container:before{
	display: none !important;
}

.search-container{
	background-color: $blue;
	padding-top: 15px;
	padding-bottom: 15px;
}

.top-bar-wrap{
	float: right;
	.smartschool{
		display: inline-block;
		margin-right: 20px;
		position: relative;
		bottom: 13px;

		a{
			color: white !important;
			padding-left: 20px;
			padding-right: 20px;
			padding-top: 10px;
			padding-bottom: 10px;
			background-color: $green;
			box-shadow: 0 0 20px 0 rgba(53,178,130,0.10);
			box-shadow: 0 2px 40px 0 rgba(22,100,131,0.25);
			border-radius: 25px;
			transition: all ease-in 0.1s;
			border-bottom: none !important;
			text-decoration: none;

			&:hover{
				background-color: #42C38D;
				border-bottom: none !important;
				color: white !important;
			}
		}
	}

	.search-widget{
		display: inline-block !important;
		position: relative;
		top: 3px;

		#searchform{
			position: relative;

			input[type="text"]{
				padding: 10px 10px 10px 0px;
				text-indent: 25px;
				border-radius: 25px;
				color: $green;
				border: none;
				float: left;
			}

			button{
				background-color: $green;
				padding: 10px 22px !important; 
				border-radius: 25px;
				border: none;
				background-image: linear-gradient(135deg, #38B983 0%, #278982 100%);
				box-shadow: 0 0 20px 0 rgba(53,178,130,0.10);
				height: 44px;
				float: left;
				margin-left: 4px;
				transition: all ease-in 0.1s;
				position: relative;

				&:hover{
					background-image: linear-gradient(115deg, #38B983 0%, #278982 100%);
				}


				&::after{
					content: url('../dist/images/search-icon.png');
					color: white;
					position: absolute;
					right: 9px;
					top: 8px;
				}
			}
		}
	}

}

/* Nav */

#nav-main{
	margin-bottom: 0;
	padding-left: 0;
	padding-top: 25px;
	padding-bottom: 25px;
	.navbar-brand{
		height: auto !important;
		padding: 0 !important;
		img{
			width: 200px;
		}
	}
	#menu-entries{
		.navbar-nav{
			float: right;

			.nav-item{
				margin-left: 10px;
				margin-right: 10px;
				margin-bottom: 10px;

				.nav-link{
					background-color: transparent !important;
					color: black !important;
					padding-top: 8px !important;
					padding-bottom: 8px !important;
					padding-right: 0;
					padding-left: 0;
					border-bottom: 3px solid transparent;

					&:hover{
						background-color: transparent;
						color: $green !important;
						border-bottom: 3px solid $green;
					}

				}

				&:nth-last-of-type(3){
					margin-right: 0;

					.nav-link{
						color: white !important;
						padding-left: 20px;
						padding-right: 20px;
						background-color: $blue !important;
						box-shadow: 0 2px 40px 0 rgba(22,100,131,0.25);
						border-radius: 25px;
						transition: all ease-in 0.1s;
						border: none !important;

						&:hover{
							background-color: rgba(22, 100, 131,0.85);
							border-bottom: 3px solid transparent;
							color: white !important;
						}
					}
					
					.dropdown-menu{
						background-color: $blue;

						a, .dropdown-item{
							color: white !important;
							box-shadow: none !important;
							border-radius: 0 !important;
							background: none !important;
						}
					}		
				}

			}

			.nav-item.current_page_item{
				&:nth-last-of-type(3){
					.nav-link{
						color: white !important;
					}

					.dropdown-menu{
						.nav-link{
							color: white !important;
						}
					}
				}
			}

			.smartschool{
				display: none;
				margin-top: 15px;
				margin-right: 0;
				margin-left: 20px;
				margin-bottom: 25px;


				.nav-link{
					color: white !important;
					padding-left: 20px;
					padding-right: 20px;
					background-color: $green !important;
					box-shadow: 0 2px 40px 0 rgba(22,100,131,0.25);
					border-radius: 25px;
					transition: all ease-in 0.1s;

					&:hover{
						background-color: rgba(22, 100, 131,0.85);
						border-bottom: 3px solid transparent;
						color: white !important;
					}
				}
			}

			.search-widget{
				display: none;
				position: relative;
				top: 3px;

				#searchform{
					position: relative;

					input[type="text"]{
						padding: 10px 25px;
						text-indent: 0px;

						color: $green;
						border: none;
						float: left;
						border-bottom: 3px solid $green;
					}

					button{
						background-color: $green;
						padding: 10px 22px !important; 
						border-radius: 25px;
						border: none;
						background-image: linear-gradient(135deg, #38B983 0%, #278982 100%);
						box-shadow: 0 0 20px 0 rgba(53,178,130,0.10);
						height: 44px;
						float: left;
						margin-left: 4px;
						transition: all ease-in 0.1s;
						position: relative;

						&:hover{
							background-image: linear-gradient(115deg, #38B983 0%, #278982 100%);
						}


						&::after{
							content: url('../dist/images/search-icon.png');
							color: white;
							position: absolute;
							right: 9px;
							top: 8px;
						}
					}
				}
			}

			.dropdown{
				.dropdown-nav-item{
					padding: 0 10px;

					.dropdown-item, .nav-link{
						transition: all ease-in 0.1s;
						border-bottom: none;

						&:hover{
							background-color: transparent;
							color: $green !important;
						}

						&:focus{
							background-color: transparent;
						}

					}
				}

				.dropdown-sub{
					position: absolute;
					left: 157px;
					top: 0px;

					li{
						padding: 0;
						a{
							color: black !important;
						}
					}
				}
			}

			.nav-item.current_page_item{
				.nav-link{
					color: $green !important;
					border-bottom: 3px solid $green;
				}
				.dropdown-menu{
					.nav-link{
						color: black !important;
						border-bottom: none !important;
					}
				}

			}
		}
	}
}

/* Header Image */

.header-image{
	background-size: cover !important;
	padding-top: 25%;
	position: relative;

	&:after{
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0;
		height: 0;
		border-left: 0 solid transparent;
		border-right: 100vw solid transparent;
		border-bottom: 5vw solid white;
		z-index: 1;
	}

	.cta-container{
		position: absolute;
		right: 150px;
		bottom: -20px;
		z-index: 2;

		.newsletter-cta{
			display: block;
			color: white !important;
			background-color: $green;
			transform: skewY(3deg);
			padding: 20px 50px;
			font-size: 20px;
			font-family: 'Raleway', sans-serif !important;
			text-align: center;
			transition: all ease-in 0.1s;

			&:hover{
				text-decoration: none;
				background-color: #42C38D;
			}

			p{
				font-family: 'Raleway', sans-serif !important;
				color: white;
				margin: 0;
				transform: skewY(177deg);
				line-height: 24px;
			}

		}
	}
}

.content-with-featured-img{
	position: relative;
	bottom: 30px !important;
}

.page-template-template-front{
	.header-image{
		padding-top: 32%;
	}

}

/**
 * Mobile only
 **/

@include media-breakpoint-down(md) {
	#menu-entries{
		display: none !important;
	
		&.show{
			display: inherit !important;
		}
	}
}