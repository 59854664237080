//Required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

//Extra
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
//@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
//@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
//@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
//@import "~bootstrap/scss/button-group";
//@import "~bootstrap/scss/input-group";
//@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
//@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/pagination";
//@import "~bootstrap/scss/badge";
//@import "~bootstrap/scss/jumbotron";
//@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
//@import "~bootstrap/scss/media";
//@import "~bootstrap/scss/list-group";
//@import "~bootstrap/scss/close";
//@import "~bootstrap/scss/modal";
//@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

// Global
@import "lib/global/variables";
@import "lib/global/typography";

// Modules
@import "lib/modules/mixins";
@import "lib/modules/fonts";

// Templates
@import "lib/templates/front";

/* Fonts */

@import url('https://fonts.googleapis.com/css?family=Raleway:700|Source+Sans+Pro:300,400,600&display=swap');

/* Variables */

$blue: #166483;
$green: #38B983;

/* General */

*{
   font-family: 'Source Sans Pro', sans-serif;
}

a{
	color: $blue !important;
}

b{
	font-weight: 600 !important;
}

p, ul, ol{
	color: #343434;
	font-size: 16px;
	line-height: 24px;
}

p b, ul b{
	font-weight: 700 !important;
}

h1{
    font-family: 'Raleway', sans-serif !important;
	font-size: 38px !important;
	line-height: 52px;
	font-weight: 700 !important;
	color: $green;
	margin-bottom: 30px !important;
}

h1:after{
	content:"";
	border-bottom: 5px solid rgba(56, 185, 131, 0.2);
	display: block;
	width: 100px;
	margin-top: 30px;
}

h2{
	font-family: 'Raleway', sans-serif !important;
	font-size: 40px !important;
	font-weight: 600 !important;
}

.col{
	padding: 0;
}

/* Partials */

@import 'lib/partials/header.scss';
@import 'lib/partials/footer.scss';
@import 'lib/partials/front.scss';
@import 'lib/partials/subpage.scss';
@import 'lib/partials/studeren.scss';
@import 'lib/partials/schoolkosten.scss';
@import 'lib/partials/plugins.scss';
@import 'lib/partials/responsive.scss';

