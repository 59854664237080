.content-template{

	padding-bottom: 50px;
	
	h2{
		color: $blue;
		font-size: 32px !important;
		margin-bottom: 30px;
		margin-top: 35px;
	}

	h3{
		color: $green;
		font-size: 26px !important;
		margin-bottom: 20px;
		margin-top: 35px;
	}

	a{
		color: $green !important;
		font-weight: bold;
		border-bottom: 3px solid transparent;
		transition: all ease-in 0.1s;
		text-decoration: none;

		&:hover{
			border-bottom: 3px solid $green;
		}
	}

	ul{
	  list-style: none;
	  padding: 0;

		li{
			line-height: 30px;

			&:before{
			 	content: "\2022";
				color: $green;
				font-weight: bold;
				display: inline-block;
				margin-right: 15px;
				font-size: 20px;
			}
		}

		li.blocks-gallery-item{
			&:before{
				display: none !important;
			}
		}
	}

	.cta-404{
		display: inline-block;
		background-color: $blue;
		color: white !important;
		padding: 12px 45px;
		border-radius: 25px;
		text-decoration: none;
		transition: all ease-in 0.1s;
		border: none !important;
		margin-top: 25px;

			&:hover{
				background-color: #206E8D;
			}
	}


}

.news-overview{

	background-color: transparent;
	padding-top: 50px;

	.row{
		margin-bottom: 30px;

		&:last-of-type{
			margin-bottom: 0;
		}

		h3{
			color: black !important;
			font-size: 20px !important;
			line-height: 28px !important;
			font-weight: bold !important;
			margin-bottom: 20px !important;
			margin-top: 0 !important;
		}
	}
}

.datum{
	font-style: italic;
	font-size: 18px;
	margin-bottom: 50px;

	span{
		color: $blue;
	}
}

.page-template-template-news .content-template{

	padding-bottom: 0px;

}

.search-results__item_wrap{
	margin-bottom: 25px;

	.search-results__item{
		margin-bottom: 50px;
		background-color: #F2F2F2;
		padding: 25px 25px;
		height: 100%;

		h2{
			a{
				text-decoration: none;

				&:hover{
					color: #206E8D !important;
				}
			}

			&:after{
				content: "";
				border-bottom: 5px solid $blue;
				opacity: 0.2;
				display: block;
				width: 50px;
				margin-top: 10px;
			}
		}

		p{
			margin-top: 25px;
		}
	}

}

.search-no-results{
	#searchform{
		position: relative;

		input[type="text"]{
			padding: 10px 10px 10px 0px;
			text-indent: 10px;
			color: $green;
			border: none;
			float: left;
			border-bottom: 3px solid #38B983;
		}

		button{
			background-color: $green;
			padding: 10px 22px !important; 
			border-radius: 25px;
			border: none;
			background-image: linear-gradient(135deg, #38B983 0%, #278982 100%);
			box-shadow: 0 0 20px 0 rgba(53,178,130,0.10);
			height: 44px;
			float: left;
			margin-left: 4px;
			transition: all ease-in 0.1s;
			position: relative;

			&:hover{
				background-image: linear-gradient(115deg, #38B983 0%, #278982 100%);
			}


			&::after{
				content: url('../dist/images/search-icon.png');
				color: white;
				position: absolute;
				right: 9px;
				top: 8px;
			}
		}
	}
}